<template>
  <div class="content" :style="'background: url('+sgame.backimg+') 0% 0% / 100% no-repeat #f6f6f6;'">
  <!-- 头部 -->
    <div class="headcontent">
      <div class="headcontent-left">
        <img
          :src="sgame.icon"
          alt=""
        />
        <div>
          <p>{{sgame.sgname}}</p>
          <p>奖励以等额彩贝发放</p>
          <p>注意游戏结束后再退出</p>
        </div>
      </div>
      <!-- <div class="headcontent-right">
        <p>我的红包券</p>
        <p>¥{{balancemoney.balance}}</p>
      </div> -->
    </div>
    <div class="rule" @click="profit = true">规则</div>
    <!-- 奖励 -->
    <div class="fraction">
      <div class="head">我的最高分：{{rule.highest}}</div>
      <div class="zhong">
        <p>已领取：{{rule.gainMoney}}{{ unitauthorize('元') }}</p>
        <p>总奖励：{{rule.totalMoney}}{{ unitauthorize('元') }}</p>
      </div>
      <div class="money">
        <div class="moeny-list">
          <div v-for="(item , index) in moneylist" :key="index"  @click="Toasts(item)">
            <p :style="item.status==-1?'opacity: 0.3;background: url(https://ppyos.xijiuyou.com/imgs/smartgame/big-red-pack.png)  0% 0% / 100% 100% no-repeat;':item.status==0 ||item.status==1 || item.status==2  || item.status==4?'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/big-red-pack.png)  0% 0% / 100% 100% no-repeat;':''">
              <span :style="item.status==-1 || item.status==0 || item.status==1 || item.status==4?'display:flex;align-items: center;justify-content: center;height: 18px;background: rgba(255, 221, 146, 0.8);box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.5);border-radius: 10px;margin: 28px auto 0;':item.status==1?'':''">{{item.money}}{{ unitauthorize('元') }}</span>
            </p>
            <img
              :src="item.status<0?'https://ppyos.xijiuyou.com/imgs/smartgame/circle-no.png':'https://ppyos.xijiuyou.com/imgs/smartgame/circle-yes.png'"
              alt=""
            />
            <!-- {{item.event}} -->
            <p>{{million(item.event.split("分")[0])}}分</p>
          </div>
        </div>
        <div class="xian">
          <div :style="num==0?'width:4%;':num==1?'width:16%;':num==2?'width:40%;':num==3?moneylist.length==4?'width:69%':'width:60%;':num==4?moneylist.length==4?'width:100%':'width:80%;':'100%'"></div>
        </div>
      </div>
    </div>
    <!-- 领取成功列表 -->
    <div class="task">
      <div class="tasklist" :style="`margin-top:-${mag}px;`">
        <div v-for="(item , index) in list" :key="index">
          <div>
            <img
              :src="item.icon"
              alt=""
            />
            {{item.title}}
          </div>
          <p>{{item.message}}</p>
        </div>
      </div>
    </div>
    <!-- 去玩游戏 -->
    <div class="fixed">
      <div  @click="gogame()">去玩游戏</div>
    </div>
    <xwpopup :isshowpopup="profit" @close="closeprofit()" :hiddenClose="false">
      <img class="closeprofit" src="https://xi9y.oss-cn-hangzhou.aliyuncs.com/sgame/rule/pk.png" alt="">
    </xwpopup>
  </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import game from "../../api/game"
import { Toast } from "vant";
//import timeutils from "@/utils/TimeFormat";
import unit from "../../utils/unit";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      mag: 0,
      list:[],
      profit:false,
      moneylist:[],
      sgame:{},
      timestamp:"",
      stimeStamp:"",
      rule:{},
      num:0,
      balancemoney:{}
    };
  },
  mounted() {
    const that =this;
    setInterval(() => {
      if (this.mag > 6 * 50) {
        this.mag = 0;
      } else {
        this.mag++;
      }
    }, 30);
    this.awardScrolling();
    this.awardSet();
    this.balance();
    document.addEventListener('visibilitychange', function(){
      if(!document.hidden){
        that.awardScrolling();
        that.awardSet();
        that.balance();
      }
    });
  },
  deactivated(){
    const that =this;
    document.removeEventListener('visibilitychange',function(){
        that.awardScrolling();
        that.awardSet();
        that.balance();
    });
  },
  methods:{
    unitauthorize(name){
      return unit.authorize(name)
    },
    // 红包券列表
    balance(){
      game.balance().then(res=>{
        this.balancemoney = res.data
      })
    },
    million (value){//过万处理
      let num;
      if(value > 9999){//大于9999显示x.xx万
          num=(Math.floor(value/1000)/10) + '万';
      }else if( value < 9999 && value>-9999){
        num=value
      }else if(value<-9999){//小于-9999显示-x.xx万
          num = -(Math.floor(Math.abs(value)/1000)/10)+'万'
      }
      return num;
    },
    // 规则弹窗
    closeprofit(){
      this.profit = false;
    },
    // 点击钱兑换
    Toasts(item){
      if(item.status==1 || item.status==0){
        const that =this
        game.drawAward({gid:this.$route.query.sgid}).then(()=>{
          Toast('兑换成功');
          setTimeout(function(){
            that.awardScrolling();
            that.awardSet();
            this.balance();
          },500)
        })
      }
      
    },
    // 去玩游戏
    gogame(){
      game.smartstart({gid:this.$route.query.sgid}).then(res=>{
        try{
          window.android.openH5Game(res.data.link)
        }catch(err){
            console.log(err)
        }
      })
    },
    // 兑换列表
    awardSet(){
      this.num=0;
      game.awardSet({gid:this.$route.query.sgid}).then(res=>{
        this.moneylist = res.list;
        res.list.map(res=>{
          if(res.status>=0){
            this.num++
          }
        })
        console.log(this.num)
        this.sgame = res.sgame;
        this.rule = res;
      })
    },
    // 获得奖励成功列表
    awardScrolling(){
      game.awardScrolling({gid:this.$route.query.sgid}).then(res=>{
        this.list = res.list
      })
    }
  }
};
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/temp01.png")
    no-repeat;
  background-size: 100%;
  background-color: #f6f6f6;
  padding-top: 28px;
}
.headcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}
.headcontent img {
  width: 68px;
  height: 68px;
  margin-right: 8px;
  border-radius: 10px;
}
.headcontent-left {
  display: flex;
  align-items: center;
}
.headcontent-left p {
  font-size: 20px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
.headcontent-left p:nth-child(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 12px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  margin-top: 6px;
  margin-bottom: 15px;
}
.headcontent-left p:nth-child(3) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);

}
.headcontent-right {
  width: 85px;
  height: 57px;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/item-warp.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 6px;
}
.headcontent-right p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #893f00;
}
.headcontent-right p:nth-child(2) {
  font-size: 14px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: normal;
  color: #893f00;
  margin-top: 10px;
  padding-left: 4px;
}
.rule {
  width: 24px;
  height: 42px;
  background: rgba(255,255,255,0.7);
  box-shadow: inset 0px 1px 0px 0px #FFFFFF;
  border-radius: 6px 0px 0px 6px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  position: absolute;
  padding: 3px;
  text-align: center;
  right: 0;
  top: 50px;
}
.fixed {
  position: fixed;
  width: 100%;
  height: 68px;
  bottom: 0;
  left: 0;
  background: #ffffff;
}
.fixed div {
  margin: 0 auto;
  margin-top: 10px;
  height: 48px;
  background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
  border-radius: 24px;
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.fraction {
  width: calc(100% - 24px);
  height: 228px;
  background: #ffffff;
  border-radius: 15px;
  margin: 40px auto 0;
  padding: 1px;
}
.head {
  width: 230px;
  height: 39px;
  margin: -5px auto 0;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/page-title.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 39px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.zhong {
  width: calc(100% - 24px);
  height: 28px;
  background: #fff2ed;
  border-radius: 14px;
  display: flex;
  justify-content: space-around;
  margin: 24px auto;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.moeny-list {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: center;
}
.moeny-list div p:nth-child(1) {
  width: 52px;
  padding: 0 2px;
  height: 64px;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/open-red-pack.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding-top: 12px;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #770000;
}
.moeny-list div img {
  width: 9px;
  height: 9px;
  position: relative;
  z-index: 1;
}
.moeny-list div p:nth-child(3) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  text-align: center;
  width: 49px;
}
.moeny-list div p span {
  display: block;
}
.money {
  height: 100px;
  position: relative;
}
.xian {
  position: absolute;
  width: 90%;
  height: 3px;
  background: rgba(255, 86, 117, 0.1);
  border-radius: 2px;
  left: 5%;
  bottom: 24px;
  border-radius: 20px;
  overflow: hidden;
}
.xian div {
  height: 100%;
  border-radius: 2px;
  overflow: hidden;
  background: RGBA(255, 86, 117, 1);
}
.task {
  width: calc(100vw - 24px);
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  padding: 2px 15px;
  margin-top: 10px;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  overflow: hidden;
  height: 200px;
  margin-bottom: 80px;
}
.tasklist div {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid rgba(231, 231, 231, 1);
}
.tasklist div span {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
}
.tasklist div img {
  width: 30px;
  height: 30px;
  background: #89cca4;
  border-radius: 50%;
  margin-right: 12px;
}
.closeprofit{
  width: calc(100% - 100px);
  margin-top: 80px;
}
</style>