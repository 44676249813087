const unit = {
    authorize(name) {
        try {
            if(window.android.getPackageName() === "com.jiuyou.caibeiwan"){
                if(name === "0元"){
                    return '0奖券';
                }else if(name === "提现"){
                    return '奖券兑换';
                }else{
                    return '奖券';
                }
            }else{
                return name;
            }
        } catch (err) {
            return name;
        }
    }
  }

  export default unit;
