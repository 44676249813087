import axios from "axios";
// import qs from'qs';
const game = {
  balance() {
    //用户小游戏余额
    return axios.get("/api/ppy/gamecenter/smart/balance");
  },
  changeBalConfig() {
    //用户小游戏余额
    return axios.get("/api/ppy/gamecenter/smart/changeBalConfig");
  },
  changeBalance(id) {
    //用户小游戏余额转换请求
    return axios.get("/api/ppy/gamecenter/smart/changeBalance", {
      params: {
        configId: id,
      },
    });
  },
  changeBalResult(id) {
    //用户小游戏余额转换请求
    return axios.get("/api/ppy/gamecenter/smart/changeBalResult" + id);
  },
  changeBalRecord(data) {
    //用户小游戏余额
    return axios.get("/api/ppy/gamecenter/smart/changeBalRecord",{
      params: data
    });
  },
  gameList(data) {
    //用户小游戏余额
    return axios.get("/api/ppy/gamecenter/smart/gameList",{
      params: data
    });
  },
  pklistBase(data) {
    //pk排行榜小游戏排行奖励等基础信息
    return axios.get("/api/ppy/gamecenter/smart/pklistBase",{
      params: data
    });
  },
  pklistRank(data) {
    //pk排行榜小游戏排行榜某个日期场次的排行榜
    return axios.get("/api/ppy/gamecenter/smart/pklistRank",{
      params: data
    });
  },
  smartstart(data) {
    //点击开始小游戏
    return axios.get("/api/ppy/gamecenter/smart/start",{
      params: data
    });
  },
  awardScrolling(data) {
    //赏金小游戏用户领奖滚动信息
    return axios.get("/api/ppy/gamecenter/smart/awardScrolling",{
      params: data
    });
  },
  awardSet(data) {
    //赏金小游戏奖励信息接口
    return axios.get("/api/ppy/gamecenter/smart/awardSet",{
      params: data
    });
  },
  bannerfind(numbers){
    return axios.get("/api/ppy/usercenter/banner/find",{
      params: {
        type:numbers
      }
    });
  },
  marquee(){
    return axios.get("/api/ppy/usercenter/invite/marquee");
  },
  drawAward(data){
    return axios.get("/api/ppy/gamecenter/smart/drawAward",{
      params:data
    });
  },
  dialogShower(){
    return axios.get("/api/ppy/usercenter/user/dialogShower",{
      params:{
        type:"CONCH_COIN_NEWBIE_AWARDED"
      }
    });
  },
  balanceLogs(data){
    return axios.get("/api/ppy/gamecenter/smart/balanceLogs",{
      params:data
    });
  },
  wechatRpSkn(){
    return axios.get("/api/ppy/usercenter/rpskn/wechatRpSkn");
  },
  wechatRpSknConvert(data){
    return axios.get("/api/ppy/usercenter/rpskn/wechatRpSknConvert",{
      params:data
    });
  },
  myRecords(data){
    return axios.get("/api/ppy/usercenter/rpskn/myRecords",{
      params:data
    });
  },
  getRpSknInfo(data){
    return axios.get("/api/ppy/usercenter/rpskn/getRpSknInfo",{
      params:data
    });
  },
  pccode(){
    return axios.get("/api/ppy/gamecenter/smart/privilege/user/privilege_ad_free");
  },
};
export default game;
